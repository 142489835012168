import React from 'react'

const ErrorRoute = React.lazy(() => import('./views/errorPage'))
const LoginRoute = React.lazy(() => import('./views/login'))


const Devices = React.lazy(() => import('./views/devices/devices'))
const Infos = React.lazy(() => import('./views/infos/infos'))
const AddDevice = React.lazy(() => import('./views/devices/addDevice'))

const MapDevices = React.lazy(() => import('./views/devices/mapDevices'))

const routes = [
    { path: '/error', exact: true, name: 'Error',element:ErrorRoute },
    { path: '/login', exact: true, name: 'Login',element:LoginRoute },
    { path: '/home', exact: true, name: 'Home',element:Devices },
    { path: '/', exact: true, name: 'Dash',element:Devices },

    { path: '/devices', exact: true, name: 'Devices',element:Devices },
    { path: '/devices/map', exact: true, name: 'MapDevices',element:MapDevices },
    { path: '/devices/add', exact: true, name: 'AddDevice',element:AddDevice },
    { path: '/infos', exact: true, name: 'Infos',element:Infos },

]

export default routes